import React from "react";
import { Link as NavLink } from "react-scroll";

const Header = () => {
  return (
    <header className="headerArea">
      <div className="links">
        <NavLink
          to="welcome"
          activeClassName="active"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-51}
        >
          ACCUEIL
        </NavLink>
        <NavLink
          to="why"
          activeClassName="active"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-51}
        >
          POURQUOI
        </NavLink>
        <NavLink
          to="what"
          activeClassName="active"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-51}
        >
          QUOI
        </NavLink>
        <NavLink
          to="who"
          activeClassName="active"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-51}
        >
          {window.screen.width > 820
            ? "QUI PEUT REMPLIR CE QUESTIONNAIRE"
            : "QUI"}
        </NavLink>
        <NavLink
          to="using"
          activeClassName="active"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-51}
        >
          {window.screen.width > 820 ? "UTILISATION DE L'OUTIL" : "UTILISATION"}
        </NavLink>
        <NavLink
          to="results"
          activeClassName="active"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-51}
        >
          RÉSULTATS
        </NavLink>
        <NavLink
          to="credits"
          id="last"
          activeClassName="active"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-51}
        >
          GÉNÉRIQUE
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
